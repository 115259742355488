








































































































































































import { faBell } from '@fortawesome/free-regular-svg-icons/faBell'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import NotificationCenterService from '~/services/notification/NotificationCenterService'
import NotificationCenterList from './NotificationCenterList.vue'
import NoResultsFound from '~/components/car/classifieds/search/results/rows/NoResultsFound.vue'
import { faCheck, faCog } from '@fortawesome/free-solid-svg-icons'
import { NotificationTypeEnum } from '~/models/notification-center/types'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import NotificationService from '~/services/notification/NotificationService'
import CPagination from '~/components/shared/configurable/pagination/CPagination.vue'
import CInfiniteScroll from '~/components/shared/configurable/infinite-scroll/CInfiniteScroll.vue'

export default Vue.extend({
  components: {
    CPagination,
    NotificationCenterList,
    NoResultsFound,
    CCustomSelect,
    COptionWithCount,
    CInfiniteScroll
  },
  props: {
    visibleHeader: {
      type: Boolean,
      default: true
    },
    infiniteScrollPagination: {
      type: Boolean,
      default: true
    },
    page: { type: Number, default: 1 },
    notificationTypesProp: { type: Array, default: null },
    displayInPage: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: !this.displayInPage,
      pageNum: this.page,
      notifications: [],
      selectedNotificationTypes: this.notificationTypesProp,
      isNotificationSwitchActive: false
    }
  },
  computed: {
    ...mapDeps({
      notificationCenterService: NotificationCenterService,
      notificationService: NotificationService
    }),
    ...mapGetters(USER_NS, {
      isGuest: 'isGuest'
    }),
    ...mapState(USER_NS, {
      rows: state => state.notificationsCenter.notifications.rows,
      pagination: state => state.notificationsCenter.notifications.pagination,
      notificationTypes: state => state.notificationsCenter.notificationTypes,
      isResultsLoading: state => state.notificationsCenter.loading,
      unreadNotifications: state => state.unreadNotifications
    }),
    notificationsSettingsUrl() {
      return this.$router.resolve({ name: '__notification_settings' }).href
    },
    icons() {
      return { bell: faBell, gear: faCog, check: faCheck }
    },
    canSeeSettings(): boolean {
      return this.notificationService.userIsEligibleForNotifications()
    },
    paginationPages() {
      if (this.pagination) {
        return Math.ceil(this.pagination.total / this.pagination.perPage)
      }
      return 0
    },
    notificationTypesOptions() {
      if (this.notificationTypes) {
        const normalizedNotificationTypes = this.notificationTypes.map(n => ({
          count: n.count,
          text: n.name,
          value: n.value
        }))
        return normalizedNotificationTypes
      }
      return null
    }
  },
  watch: {
    rows() {
      if (this.notifications.length && this.infiniteScrollPagination) {
        this.notifications = this.notifications.concat(this.rows)
      } else {
        this.notifications = this.rows
      }
    },
    page(newVal: number) {
      this.pageNum = newVal
    },
    notificationTypesProp(newVal: Array<NotificationTypeEnum>) {
      this.selectedNotificationTypes = newVal
    },
    isResultsLoading() {
      this.isLoading = this.isResultsLoading
    }
  },
  created() {
    if (this.notifications.length && this.infiniteScrollPagination) {
      this.notifications = this.notifications.concat(this.rows)
    } else {
      this.notifications = this.rows
    }

    if (
      !this.displayInPage &&
      this.pageNum === 1 &&
      !this.selectedNotificationTypes
    ) {
      this.clearData()
    }
  },
  methods: {
    ...mapActions(USER_NS, [
      'fetchNotificationCenterData',
      'readAllNotifications'
    ]),
    async handleFetchNotifications(
      page: number = null,
      types: Array<NotificationTypeEnum> = null
    ) {
      await this.fetchNotificationCenterData({ page, types })
    },
    handlePageChange(page: number) {
      this.$emit('page-change', page)
      if (this.pageNum !== page) {
        this.handleFetchNotifications(page, this.selectedNotificationTypes)
      }
      if (this.pageNum !== page && !this.displayInPage) {
        this.pageNum = page
      }
      if (!this.displayInPage) {
        this.handleFetchNotifications(page, this.selectedNotificationTypes)
      }
    },
    clearData() {
      this.notifications = []
      this.pageNum = 1
    },
    handleNotificationTypeChange(
      notificationTypes: Array<NotificationTypeEnum>
    ) {
      this.$emit('notification-type-change', notificationTypes)
      this.selectedNotificationTypes = notificationTypes
      this.pageNum = 1
      if (!this.displayInPage) {
        this.isLoading = true
        this.clearData()
      }
      this.handleFetchNotifications(1, this.selectedNotificationTypes)
    },
    async onReadAll() {
      await this.readAllNotifications()
    }
  }
})
