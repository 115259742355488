






















































import { defineComponent } from '@nuxtjs/composition-api'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import CPicture from '~/components/shared/configurable/image/CPicture.vue'
import { defineComponentTranslations } from '~/utils/i18n'
import { useUserAgent } from '~/compositions/user-agent'

export default defineComponent({
  components: { CPicture, CImg },
  setup() {
    const { isMobile } = useUserAgent()

    return { isMobile }
  },
  i18n: defineComponentTranslations({
    paying_dearly: {
      en: 'Are you paying dearly for your <span>service</span>?',
      el: 'Μήπως πληρώνεις ακριβά το <span>service</span> σου;'
    },
    book_appointment: {
      en: 'book',
      el: 'κλείσε'
    },
    through: {
      en: 'through',
      el: 'μέσω'
    }
  })
})
