import { PropType } from '@nuxtjs/composition-api'
import { Searchable } from '~/models/quick-search/types'
import { Size } from '~/models/app/size'
import { CategoryId } from '~/models/category/types'

export const facetProps = {
  urlArg: {
    type: String,
    default: null
  },
  name: {
    type: String,
    default: null
  },
  humanName: {
    type: String,
    default: ''
  },
  searchables: {
    type: Array as PropType<Searchable[]>,
    default: () => []
  },
  value: {
    type: String,
    default: null
  },
  size: {
    type: String as PropType<Size>,
    default: Size.MD
  }
}

export const categoriesMap = [
  {
    name: 'vehicles',
    id: String(CategoryId.VEHICLES),
    children: [
      { name: 'cars', id: String(CategoryId.CARS) },
      { name: 'bikes', id: String(CategoryId.BIKES) },
      { name: 'boats', id: String(CategoryId.BOATS) },
      {
        name: 'commercials',
        id: String(CategoryId.COMMERCIAL),
        children: [
          { name: 'tractors', id: String(CategoryId.TRACTORS) },
          { name: 'semitrailers', id: String(CategoryId.SEMITRAILERS) },
          { name: 'forklifts', id: String(CategoryId.FORKLIFTS) },
          { name: 'buses', id: String(CategoryId.BUSES) },
          { name: 'builders', id: String(CategoryId.BUILDERS) },
          { name: 'trailers', id: String(CategoryId.TRAILERS) },
          { name: 'taxis', id: String(CategoryId.TAXIS) },
          { name: 'semitrucks', id: String(CategoryId.SEMITRUCKS) },
          { name: 'trucks', id: String(CategoryId.TRUCKS) },
          { name: 'vans', id: String(CategoryId.VANS) }
        ]
      },
      { name: 'campers', id: String(CategoryId.CAMPERS) },
      { name: 'bicycles', id: String(CategoryId.BICYCLES) },
      { name: 'hobby', id: String(CategoryId.HOBBIES) }
    ]
  },
  {
    name: 'parts',
    id: String(CategoryId.PARTS),
    children: [
      { name: 'for-cars', id: String(CategoryId.PARTS_CARS) },
      { name: 'for-bikes', id: String(CategoryId.PARTS_BIKES) },
      { name: 'for-trucks', id: String(CategoryId.PARTS_TRUCKS) },
      { name: 'for-tractors', id: String(CategoryId.PARTS_TRACTORS) },
      { name: 'for-tools', id: String(CategoryId.PARTS_TOOLS) },
      { name: 'for-boats', id: String(CategoryId.PARTS_BOATS) },
      { name: 'for-bicycles', id: String(CategoryId.PARTS_BICYCLES) },
      { name: 'for-motorhomes', id: String(CategoryId.PARTS_CAMPERS) },
      { name: 'for-buses', id: String(CategoryId.PARTS_BUSES) },
      { name: 'for-builders', id: String(CategoryId.PARTS_BUILDERS) },
      { name: 'for-forklifts', id: String(CategoryId.PARTS_FORKLIFTS) }
    ]
  },
  {
    name: 'plot',
    id: String(CategoryId.PLOT)
  },
  {
    name: 'rentals',
    id: String(CategoryId.RENTALS)
  },
  {
    name: 'xyma',
    id: String(CategoryId.XYMA)
  },
  {
    name: 'service',
    id: String(CategoryId.SERVICE)
  }
]
