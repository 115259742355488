







import Vue, { PropOptions } from 'vue'
import { Option } from '~/models/shared/types'

export default Vue.extend({
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      required: true
    } as PropOptions<Option>
  }
})
