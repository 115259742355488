











import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    sources: {
      type: Object as PropType<Record<string, string>>,
      default: () => {}
    }
  },
  setup(props) {
    const { sources } = toRefs(props)

    function getBreakpointWidth(breakpoint: string) {
      switch (breakpoint) {
        case 'sm': {
          return '576'
        }
        case 'md': {
          return '768'
        }
        case 'lg': {
          return '992'
        }
        case 'xl': {
          return '1200'
        }
        case '2xl':
        default: {
          return '1536'
        }
      }
    }

    const formattedSources = computed(() =>
      Object.entries(sources.value).map(([breakpoint, src]) => ({
        width: getBreakpointWidth(breakpoint),
        src
      }))
    )

    return { formattedSources }
  }
})
