

















import Vue, { PropType } from 'vue'
import { FeedVideo } from '~/models/user/types'

export default Vue.extend({
  props: {
    video: {
      type: Object as PropType<FeedVideo>,
      required: true
    },
    showLink: {
      type: Boolean,
      required: false,
      default: true
    },
    feedLabel: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    videoUrl() {
      return this.video.targetUrl
    }
  },
  mounted() {
    this.$emit('image-load')
  },
  methods: {
    hrefClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.showLink) {
        this.videoClicked(e)
      }
      return false
    },
    videoClicked(_evt) {
      this.$analytics.recordEvent({
        namespace: 'n_index_feed_video',
        action: 'click',
        label: this.feedLabel + ' (' + this.video.title + ')'
      })

      return window.open(this.videoUrl)
    }
  }
})
