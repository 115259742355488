import { ref, computed, Ref } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import {
  USER_AGENT_NS,
  UserAgentState
} from '~/store/modules/shared/userAgent/state'
import { Facet } from '~/models/search/types'
import SearchService from '~/services/search/SearchService'
import { useDep } from '~/compositions/dependency-container'
import { HierarchicalCategory, CategoryId } from '~/models/category/types'
import { useI18n } from '~/compositions/i18n'
import { categoriesMap } from '~/constants/quick-search'
import { CIcon } from '~/icons/types'

export interface TopLevelCategories {
  label: string
  id: number | string
  categoryId: number | string
  icon?: CIcon
  hasSubTreeCategories: boolean
  selected: boolean
  alt: boolean
  hasOpenMenu?: boolean
  subCategories?: HierarchicalCategory[]
  isExternalLink: boolean
}

const useQuickSearchMenu = (
  parentID?: Ref<string>,
  selectedSubcategoryId?: Ref<string>,
  thirdLevelSubCategoryId?: Ref<string>,
  facets?: Ref<Facet[]>,
  isThirdLevelSubCategorySelected?: Ref<boolean>
) => {
  const { getters } = useNamespacedStore<SearchState>(CLASSIFIED_SEARCH_NS)
  const { getters: userAgentGetters } = useNamespacedStore<UserAgentState>(
    USER_AGENT_NS
  )

  const { t } = useI18n()

  const searchService = useDep(SearchService)

  const topLevelCategories = ref<TopLevelCategories[]>([
    {
      label: String(t('vehicles')),
      id: String(CategoryId.VEHICLES),
      categoryId: CategoryId.VEHICLES, // for icon
      hasSubTreeCategories: true,
      hasOpenMenu: true,
      selected: true,
      alt: true,
      isExternalLink: false
    },
    {
      label: String(t('parts & accessories::parts')),
      id: String(CategoryId.PARTS),
      categoryId: CategoryId.PARTS,
      hasSubTreeCategories: true,
      hasOpenMenu: false,
      selected: false,
      alt: true,
      isExternalLink: false
    },
    {
      label: String(t('real estate')),
      id: String(CategoryId.PLOT),
      categoryId: CategoryId.PLOT,
      hasSubTreeCategories: false,
      hasOpenMenu: false,
      selected: false,
      alt: true,
      isExternalLink: false
    },
    {
      label: String(t('small classifieds')),
      id: String(CategoryId.XYMA),
      categoryId: CategoryId.XYMA,
      hasSubTreeCategories: false,
      hasOpenMenu: false,
      selected: false,
      alt: true,
      isExternalLink: false
    },
    {
      label: String(t('jobs')),
      id: String(CategoryId.JOBS),
      categoryId: CategoryId.JOBS,
      hasSubTreeCategories: false,
      hasOpenMenu: false,
      selected: false,
      alt: true,
      isExternalLink: true
    },
    {
      label: String(t('rentals')),
      id: String(CategoryId.RENTALS),
      categoryId: CategoryId.RENTALS,
      hasSubTreeCategories: false,
      hasOpenMenu: false,
      selected: false,
      alt: true,
      isExternalLink: false
    },
    {
      label: t('car_service').toString(),
      id: CategoryId.SERVICE,
      categoryId: CategoryId.SERVICE,
      hasSubTreeCategories: false,
      selected: false,
      alt: true,
      isExternalLink: false
    },
    {
      label: String(t('deals')),
      id: CategoryId.DEALS,
      categoryId: CategoryId.DEALS,
      hasSubTreeCategories: false,
      hasOpenMenu: false,
      selected: false,
      alt: true,
      isExternalLink: true
    }
  ])

  const isPc = computed(() => userAgentGetters('isPc'))
  const isFullWidth = computed(() => {
    if (isPc.value) {
      if (
        isThirdLevelSubCategorySelected?.value &&
        [
          String(CategoryId.TRUCKS),
          String(CategoryId.BUSES),
          String(CategoryId.SEMITRUCKS),
          String(CategoryId.TRACTORS),
          String(CategoryId.TAXIS)
        ].includes(thirdLevelSubCategoryId?.value as string)
      ) {
        return false
      }

      switch (selectedSubcategoryId?.value) {
        case String(CategoryId.BIKES):
          return false
        case String(CategoryId.BICYCLES):
          return false
        default:
          return true
      }
    } else {
      return true
    }
  })

  const filteredFacets = computed(() => {
    const partsSubcategories = [
      String(CategoryId.PARTS_CARS),
      String(CategoryId.PARTS_TRACTORS),
      String(CategoryId.PARTS_BUILDERS),
      String(CategoryId.PARTS_TRUCKS),
      String(CategoryId.PARTS_TOOLS),
      String(CategoryId.PARTS_FORKLIFTS),
      String(CategoryId.PARTS_BUSES),
      String(CategoryId.PARTS_BIKES),
      String(CategoryId.PARTS_BICYCLES),
      String(CategoryId.PARTS_BOATS),
      String(CategoryId.PARTS_CAMPERS)
    ]

    if (
      facets?.value.length &&
      parentID?.value === String(CategoryId.RENTALS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('pickup_location'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('rental_period')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    }

    if (
      facets?.value.length &&
      selectedSubcategoryId?.value === String(CategoryId.CARS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('fuel_type'),
        getters('getFacetByName')('finance')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      selectedSubcategoryId?.value === String(CategoryId.BIKES)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('location2')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      selectedSubcategoryId?.value === String(CategoryId.BOATS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('category'),
        getters('getFacetByName')('length'),
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('engine_power'),
        getters('getFacetByName')('fuel_type')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      selectedSubcategoryId?.value === String(CategoryId.BICYCLES)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('category'),
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('location2'),
        getters('getFacetByName')('rim_size')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.VANS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('euroclass'),
        getters('getFacetByName')('weight')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.TRUCKS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('axles'),
        getters('getFacetByName')('euroclass'),
        getters('getFacetByName')('weight'),
        getters('getFacetByName')('payload')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.BUSES)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('seats'),
        getters('getFacetByName')('mileage')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.BUILDERS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('price')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.SEMITRUCKS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('euroclass'),
        getters('getFacetByName')('weight'),
        getters('getFacetByName')('axles')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.SEMITRAILERS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('weight'),
        getters('getFacetByName')('payload'),
        getters('getFacetByName')('axles')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.TRACTORS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('engine_power')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.FORKLIFTS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('engine_power'),
        getters('getFacetByName')('maximum_capacity')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.TRAILERS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('category'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('length'),
        getters('getFacetByName')('weight'),
        getters('getFacetByName')('payload'),
        getters('getFacetByName')('axles')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      thirdLevelSubCategoryId?.value === String(CategoryId.TAXIS)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('makemodel'),
        getters('getFacetByName')('registration'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('fuel_type'),
        getters('getFacetByName')('category')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else if (
      facets?.value.length &&
      partsSubcategories.includes(selectedSubcategoryId?.value as string)
    ) {
      const filteredAndSortedFacets: Facet[] = [
        getters('getFacetByName')('category'),
        getters('getFacetByName')('condition'),
        getters('getFacetByName')('for_makemodels'),
        getters('getFacetByName')('freetext_makemodel'),
        getters('getFacetByName')('mileage'),
        getters('getFacetByName')('price'),
        getters('getFacetByName')('location2')
      ]

      return filteredAndSortedFacets.filter(f => !!f)
    } else {
      return []
    }
  })
  const quickSearchHeading = computed(() => {
    if (isThirdLevelSubCategorySelected?.value) {
      switch (thirdLevelSubCategoryId?.value) {
        case String(CategoryId.VANS):
          return t('search for vans::quick_search')
        case String(CategoryId.TRUCKS):
          return t('search for trucks::quick_search')
        case String(CategoryId.BUSES):
          return t('search for buses::quick_search')
        case String(CategoryId.BUILDERS):
          return t('search for builders::quick_search')
        case String(CategoryId.SEMITRUCKS):
          return t('search for semitrucks::quick_search')
        case String(CategoryId.SEMITRAILERS):
          return t('search for semitrailers::quick_search')
        case String(CategoryId.TRACTORS):
          return t('search for tractors::quick_search')
        case String(CategoryId.FORKLIFTS):
          return t('search for forklifts::quick_search')
        case String(CategoryId.TRAILERS):
          return t('search for trailers::quick_search')
        case String(CategoryId.TAXIS):
          return t('search for taxis::quick_search')
      }
    }

    if ([String(CategoryId.PLOT)].includes(parentID?.value as string))
      return t('search for real estate::quick_search')

    if ([String(CategoryId.RENTALS)].includes(parentID?.value as string))
      return t('vehicle rentals::quick_search')

    switch (selectedSubcategoryId?.value) {
      case String(CategoryId.CARS):
        return t('search for cars::quick_search')
      case String(CategoryId.BIKES):
        return t('search for bikes::quick_search')
      case String(CategoryId.BOATS):
        return t('search for boats::quick_search')
      case String(CategoryId.BICYCLES):
        return t('search for bicycles::quick_search')
      case String(CategoryId.COMMERCIAL):
        return t('search for commercial vehicles::quick_search')
      case String(CategoryId.CAMPERS):
        return t('search for campers::quick_search')
      case String(CategoryId.HOBBIES):
        return t('search for hobbies::quick_search')
      case String(CategoryId.PARTS_CARS):
        return t('search for car parts::quick_search')
      case String(CategoryId.PARTS_TRACTORS):
        return t('search for tractor parts::quick_search')
      case String(CategoryId.PARTS_BUILDERS):
        return t('search for builder parts::quick_search')
      case String(CategoryId.PARTS_TRUCKS):
        return t('search for truck parts::quick_search')
      case String(CategoryId.PARTS_TOOLS):
        return t('search for tool parts::quick_search')
      case String(CategoryId.PARTS_FORKLIFTS):
        return t('search for forklift parts::quick_search')
      case String(CategoryId.PARTS_BUSES):
        return t('search for bus parts::quick_search')
      case String(CategoryId.PARTS_BIKES):
        return t('search for bike parts::quick_search')
      case String(CategoryId.PARTS_BICYCLES):
        return t('search for bicycle parts::quick_search')
      case String(CategoryId.PARTS_BOATS):
        return t('search for boat parts::quick_search')
      case String(CategoryId.PARTS_CAMPERS):
        return t('search for camper parts::quick_search')
    }
  })

  const hasSubcategoriesMenus = computed(
    () =>
      ![
        String(CategoryId.PLOT),
        String(CategoryId.RENTALS),
        String(CategoryId.XYMA),
        String(CategoryId.SERVICE)
      ].includes(parentID?.value as string)
  )

  const isPartsSection = (selectedSubcategoryId: string) => {
    return [
      String(CategoryId.PARTS_CARS),
      String(CategoryId.PARTS_TRACTORS),
      String(CategoryId.PARTS_BUILDERS),
      String(CategoryId.PARTS_TRUCKS),
      String(CategoryId.PARTS_TOOLS),
      String(CategoryId.PARTS_FORKLIFTS),
      String(CategoryId.PARTS_BUSES),
      String(CategoryId.PARTS_BIKES),
      String(CategoryId.PARTS_BICYCLES),
      String(CategoryId.PARTS_BOATS),
      String(CategoryId.PARTS_CAMPERS)
    ].includes(selectedSubcategoryId)
  }

  const isBicycleSection = (selectedSubcategoryId: string) => {
    return [String(CategoryId.BICYCLES)].includes(selectedSubcategoryId)
  }

  const computeUrlFromCategoryId = (id: string) => {
    let urlCategory
    switch (id) {
      case String(CategoryId.MOTORHOMES):
        urlCategory = 'motorhomes/'
        break
      case String(CategoryId.CARAVANS):
        urlCategory =
          'caravans/?category=15421&category=15435&category=15439&category=15766&category=15830&category=15872'
        break
      case String(CategoryId.CARAVANHOUSE):
        urlCategory = `caravans/?category=${CategoryId.CARAVANHOUSE}`
        break
      case String(CategoryId.CONTAINER):
        urlCategory = `caravans/?category=${CategoryId.CONTAINER}`
        break
      case String(CategoryId.GOKARTS):
        urlCategory = 'gokarts/'
        break
      case String(CategoryId.AIRSPORTS):
        urlCategory = 'airsports/'
        break
      case String(CategoryId.WATERSPORTS):
        urlCategory = 'watersports/'
        break
      case String(CategoryId.BICYCLES):
        urlCategory = 'bicycles/'
        break
      case String(CategoryId.RADIOCONTROLS):
        urlCategory = 'radiocontrols/'
        break
      case String(CategoryId.SNOWSPORTS):
        urlCategory = 'snowsports/'
        break
      default:
        urlCategory = 'search'
    }

    return `/classifieds/${urlCategory}`
  }

  const handleGridColumsPerFacet = (name: string) => {
    if ([String(CategoryId.RENTALS)].includes(parentID?.value as string)) {
      if (['pickup_location', 'category', 'rental_period'].includes(name)) {
        return '3'
      }
    }

    if (!isPc.value) {
      if (name === 'freetext_makemodel') return '6'
      if (
        [
          'registration',
          'mileage',
          'category',
          'fuel_type',
          'location2'
        ].includes(name) &&
        ([String(CategoryId.CARS), String(CategoryId.BIKES)].includes(
          selectedSubcategoryId?.value as string
        ) ||
          [String(CategoryId.TAXIS)].includes(
            thirdLevelSubCategoryId?.value as string
          ))
      ) {
        return '6'
      }
      return '12'
    }

    if (
      name === 'makemodel' &&
      [String(CategoryId.BOATS), String(CategoryId.BICYCLES)].includes(
        selectedSubcategoryId?.value as string
      )
    ) {
      return '6'
    }
    if (
      name === 'makemodel' &&
      [
        String(CategoryId.SEMITRAILERS),
        String(CategoryId.TRACTORS),
        String(CategoryId.FORKLIFTS),
        String(CategoryId.TRAILERS),
        String(CategoryId.VANS),
        String(CategoryId.TRUCKS),
        String(CategoryId.BUSES),
        String(CategoryId.SEMITRUCKS),
        String(CategoryId.BUILDERS)
      ].includes(thirdLevelSubCategoryId?.value as string)
    ) {
      return '12'
    }
    if (
      [
        'mileage',
        'registration',
        'category',
        'location2',
        'price',
        'fuel_type',
        'finance',
        'length',
        'engine_power',
        'euroclass',
        'weight',
        'axles',
        'payload',
        'seats',
        'condition',
        'freetext_makemodel',
        'rim_size',
        'maximum_capacity'
      ].includes(name)
    )
      return '6'

    return '12'
  }

  const handleComponentBindings = (facet: Facet, hasLabel: Boolean) => {
    if (
      facet.type === 'CategoryTreeSearchHandler' &&
      facet.name === 'category' &&
      [String(CategoryId.COMMERCIAL), String(CategoryId.BICYCLES)].includes(
        selectedSubcategoryId?.value as string
      )
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true,
        isProfessionalQuickSearch: true
      }
    }
    if (
      facet.type === 'CategoryTreeSearchHandler' &&
      facet.name === 'category' &&
      parentID?.value === String(CategoryId.RENTALS)
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true,
        isRentalQuickSearch: true
      }
    }
    if (
      facet.type === 'RentalPeriodSearchHandler' &&
      facet.name === 'rental_period'
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true
      }
    }
    if (
      facet.type === 'RentalPickupLocationSearchHandler' &&
      facet.name === 'pickup_location'
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true
      }
    }
    if (facet.type === 'ConditionSearchHandler' && facet.name === 'condition') {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true
      }
    }
    if (
      facet.type === 'VehicleRegistrationSearchHandler' &&
      facet.name === 'registration'
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        hideTo: true,
        isQuickSearch: true
      }
    }
    if (facet.type === 'RangeSearchHandler' && facet.name === 'mileage') {
      if (facet.unitType === 'hours') {
        return {
          ...searchService.getFacetComponentBindings(facet),
          isQuickSearch: true
        }
      }
      return {
        ...searchService.getFacetComponentBindings(facet),
        hideFrom: true,
        isQuickSearch: true
      }
    }
    if (
      facet.type === 'RangeSearchHandler' &&
      (facet.name === 'axles' ||
        facet.name === 'rim_size' ||
        facet.name === 'maximum_capacity')
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        hideRadioButtons: true,
        isQuickSearch: true
      }
    }
    if (
      facet.type === 'WeightSearchHandler' &&
      (facet.name === 'weight' || facet.name === 'payload')
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        hideRadioButtons: true,
        isQuickSearch: true
      }
    }
    if (facet.type === 'KeyValueSearchHandler' && facet.name === 'euroclass') {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true
      }
    }
    if (facet.name === 'length') {
      return {
        ...searchService.getFacetComponentBindings(facet),
        hideRadioButtons: true,
        isQuickSearch: true
      }
    }
    if (
      facet.name === 'makemodel' &&
      [String(CategoryId.BOATS), String(CategoryId.BICYCLES)].includes(
        selectedSubcategoryId?.value as string
      )
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true,
        showOnlyMake: true
      }
    }
    if (
      facet.name === 'makemodel' &&
      [
        String(CategoryId.SEMITRAILERS),
        String(CategoryId.TRACTORS),
        String(CategoryId.FORKLIFTS),
        String(CategoryId.TRAILERS),
        String(CategoryId.VANS),
        String(CategoryId.TRUCKS),
        String(CategoryId.BUSES),
        String(CategoryId.SEMITRUCKS),
        String(CategoryId.BUILDERS)
      ].includes(thirdLevelSubCategoryId?.value as string)
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true,
        showOnlyMake: true,
        showInputSearch: true
      }
    }
    if (facet.name === 'makemodel' && !isPc.value) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearchMobile: true
      }
    }
    if (
      [
        'makemodel',
        'category',
        'location2',
        'fuel_type',
        'price',
        'finance',
        'for_makemodels',
        'freetext_makemodel',
        'engine_power',
        'seats'
      ].includes(facet.name)
    ) {
      return {
        ...searchService.getFacetComponentBindings(facet),
        isQuickSearch: true,
        hasLabel: !hasLabel
      }
    }
    return searchService.getFacetComponentBindings(facet)
  }

  const isProfessionalSubCategory = (id: string) => {
    const professionalIds = [
      String(CategoryId.TRACTORS),
      String(CategoryId.SEMITRAILERS),
      String(CategoryId.FORKLIFTS),
      String(CategoryId.BUSES),
      String(CategoryId.BUILDERS),
      String(CategoryId.TRAILERS),
      String(CategoryId.TAXIS),
      String(CategoryId.SEMITRUCKS),
      String(CategoryId.TRUCKS),
      String(CategoryId.VANS)
    ]
    return professionalIds.includes(id)
  }

  function findThirdLevelParent(
    categories: any,
    value: string,
    depth = 1,
    parent = null
  ): any {
    for (let i = 0; i < categories.length; i++) {
      if (
        depth === 3 &&
        (categories[i].name === value || categories[i].id === value)
      ) {
        return parent
      } else if (Array.isArray(categories[i].children)) {
        const found = findThirdLevelParent(
          categories[i].children,
          value,
          depth + 1,
          categories[i]
        )
        if (found) {
          return found
        }
      }
    }
    return false
  }

  function parseCategory(
    value: string,
    key: string,
    returnObj: boolean = false
  ): any {
    function findInCategories(
      categories: any,
      value: string,
      key: string
    ): string {
      for (let i = 0; i < categories.length; i++) {
        if (categories[i][key] === value) {
          if (returnObj) {
            return categories[i]
          }
          return key === 'id' ? categories[i].name : categories[i].id
        } else if (Array.isArray(categories[i].children)) {
          const found = findInCategories(categories[i].children, value, key)
          if (found) {
            return found
          }
        }
      }
      return ''
    }

    return findInCategories(categoriesMap, value, key)
  }

  return {
    computeUrlFromCategoryId,
    filteredFacets,
    quickSearchHeading,
    topLevelCategories,
    isFullWidth,
    isPartsSection,
    handleGridColumsPerFacet,
    handleComponentBindings,
    hasSubcategoriesMenus,
    isBicycleSection,
    isProfessionalSubCategory,
    findThirdLevelParent,
    parseCategory
  }
}

export default useQuickSearchMenu
