var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CLink',{staticClass:"tw-flex tw-justify-center tw-items-end sm:tw-items-center sm:tw-justify-end tw-w-full tw-bg-[#6C9E70] tw-overflow-hidden tw-relative tw-rounded-xl tw-select-none",attrs:{"to":{ name: '__landing_pages_rentals' },"draggable":"false"}},[_c('div',{staticClass:"tw-flex sm:tw-justify-end tw-justify-center tw-w-full",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.$emit('banner-click', {
        event: $event,
        path: _vm.$router.resolve({ name: '__landing_pages_rentals' }).href
      })}}},[_c('div',{staticClass:"sm:tw-p-6 tw-flex tw-justify-center tw-items-center ",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.$emit('banner-click', {
          event: $event,
          path: _vm.$router.resolve({ name: '__landing_pages_rentals' }).href
        })}}},[_c('CImg',{staticClass:"tw-hidden sm:tw-block tw-h-[4.5rem] tw-absolute tw-top-3 tw-left-1/2 tw--translate-x-1/2 sm:tw-h-auto sm:tw-top-0 sm:tw--left-4 sm:tw-translate-x-0 sm:tw-relative sm:tw-w-[36vw] md:tw-w-[36vw] lg:tw-min-w-full tw-max-w-[18.75rem] rentals-title",class:{
          'md:tw-px-4 tw-max-w-60 sm:tw-h-32': _vm.$i18n.locale !== 'el'
        },attrs:{"src":_vm.$s(
            _vm.$i18n.locale === 'el'
              ? '/nuxt-static/img/rentals/title.svg'
              : '/nuxt-static/img/rentals/title-uk.svg'
          ),"draggable":"false"}}),_vm._v(" "),_c('CImg',{staticClass:"sm:tw-hidden tw-h-12 tw-absolute tw-top-3 tw-left-1/2 tw--translate-x-1/2 sm:tw-h-auto sm:tw-top-0 sm:tw--left-4 sm:tw-translate-x-0 sm:tw-relative sm:tw-w-[36vw] md:tw-w-[36vw] lg:tw-w-full tw-max-w-[18.75rem] rentals-title",attrs:{"src":_vm.$s(
            _vm.$i18n.locale === 'el'
              ? '/nuxt-static/img/rentals/title-middle.svg'
              : '/nuxt-static/img/rentals/title-middle-uk.svg'
          ),"draggable":"false"}})],1),_vm._v(" "),_c('CImg',{staticClass:"sm:tw-hidden tw-pb-2 tw-w-40 tw-relative tw-z-10",attrs:{"draggable":"false","src":_vm.$s('/nuxt-static/img/rentals/vehicles-banner.png')}}),_vm._v(" "),_c('div',{staticClass:"tw-absolute tw-w-[195%] tw-h-24 tw-top-32 tw--left-48 sm:tw--left-20 tw-bg-grey-50 sm:tw-h-72 tw-rounded-[100%] sm:tw-top-24 sm:tw-w-[76%] sm:tw-rotate-[8deg]"}),_vm._v(" "),_c('CImg',{staticClass:"tw-hidden sm:tw-block tw-absolute sm:tw-w-[20rem] md:tw-w-[24rem] sm:tw-bottom-0 md:tw--bottom-8 lg:tw-w-[28rem] tw-left-8 lg:tw--bottom-16",attrs:{"draggable":"false","src":_vm.$s('/nuxt-static/img/rentals/rentals-man-banner.png')}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }