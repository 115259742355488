var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'tw-min-h-screen tw-px-4': !_vm.isDrawer }},[_c('div',{staticClass:"tw-border-b tw-border-b-solid tw-border-grey-200 tw-pb-4 tw-flex tw-justify-between tw-items-center",class:{ 'tw-mt-4 tw-mx-4': _vm.isDrawer, 'tw-pt-4': !_vm.isDrawer }},[_c('h3',{staticClass:"tw-font-medium tw-text-2xl"},[_vm._v("\n      "+_vm._s(_vm.$t('search'))+"\n    ")]),_vm._v(" "),(_vm.isDrawer)?_c('CIcon',{staticClass:"tw-scale-150",attrs:{"icon":_vm.icons.clear},on:{"click":function($event){return _vm.$emit('close-menu')}}}):_vm._e()],1),_vm._v(" "),_vm._l((_vm.topLevelCategories),function(section,index){return _c('div',{key:section.id},[_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-between tw-border-l-transparent tw-border-r-transparent tw-border-b tw-border-b-solid tw-border-grey-200",class:{
        '!tw-bg-blue-50 !tw-border !tw-border-solid !tw-border-blue-600 tw-rounded-lg tw-overflow-hidden':
          section.hasOpenMenu,
        'tw-mx-4': _vm.isDrawer
      }},[_c('FeedSideButton',{staticClass:"hover:tw-text-[#455a64] tw-text-base",attrs:{"title":section.label,"is-quick-search":"","href":_vm.getTopCategoryUrl(section.categoryId.toString())},on:{"click":function($event){_vm.handleTopCategorySelection(
            $event,
            section.categoryId.toString(),
            index
          )}}},[_c('CCategoryIcon',{class:{ '!tw-text-primary-500': section.selected },attrs:{"alt":section.alt,"category-ids":[section.categoryId],"no-colors":false,"in-sidebar":""}}),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('span',{class:{
              'tw-text-primary-500 !tw-font-bold': section.hasOpenMenu
            }},[_vm._v(_vm._s(section.label))]),_vm._v(" "),(section.hasSubTreeCategories)?_c('CIcon',{staticClass:"tw-scale-150 tw-mr-2",class:{
              'tw-text-primary-500 tw-rotate-180': section.hasOpenMenu
            },attrs:{"icon":_vm.icons.downArrow}}):_vm._e()],1)],1)],1),_vm._v(" "),(section.categoryId !== _vm.CategoryId.XYMA)?_c('ul',{staticClass:"list tw-mx-8",class:{ 'active-class': section.hasOpenMenu, '!tw-mx-4': !_vm.isDrawer }},_vm._l((section.subCategories),function(subCat){return _c('li',{key:subCat.id},[_c('div',{staticClass:"tw-flex tw-justify-between tw-border tw-border-solid tw-border-transparent",class:{
            '!tw-bg-blue-50 !tw-border-blue-600 tw-rounded-lg':
              subCat.selected
          }},[_c('FeedSideButton',{staticClass:"tw-text-base",attrs:{"title":subCat.humanName,"is-quick-search":"","href":_vm.getSubcategoryUrl(subCat.id.toString())},on:{"click":function($event){_vm.$emit(
                'menu-navigate',
                $event,
                _vm.getSubcategoryUrl(subCat.id.toString())
              )}}},[_c('CCategoryIcon',{class:{
                '!tw-text-primary-500': subCat.selected,
                '!tw-text-grey-700': !subCat.selected
              },attrs:{"alt":false,"no-colors":false,"category-ids":[subCat.id],"in-sidebar":""}}),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('span',{class:{
                  'tw-text-primary-500 !tw-font-bold': subCat.selected
                }},[_vm._v(_vm._s(subCat.humanName))])])],1)],1)])}),0):_vm._e()])}),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-between tw-border-l-transparent tw-border-r-transparent tw-border-b tw-border-b-solid tw-border-grey-200",class:{ 'tw-mx-4': _vm.isDrawer }},[_c('FeedSideButton',{staticClass:"tw-text-base",attrs:{"title":_vm.$t('search classified with id'),"is-quick-search":"","to":{ name: '__search_classified_by_id' }},on:{"click":function($event){_vm.$emit(
          'menu-navigate',
          $event,
          _vm.$router.resolve({ name: '__search_classified_by_id' }).href
        )}}},[_c('FeedSidebarIconContainer',[_c('CIcon',{staticClass:"icon",attrs:{"icon":_vm.icons.search}})],1),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('search classified with id')))])])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }