var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CLink',{staticClass:"tw-w-full tw-relative tw-flex tw-select-none",attrs:{"to":{ path: '/quick-search/service' },"draggable":"false"}},[_c('div',{staticClass:"tw-flex tw-justify-center tw-items-end tw-gap-0 tw-w-full tw-bg-gradient-to-r tw-from-[#1874D0] tw-to-[#004E95] tw-h-full tw-rounded-xl tw-text-white",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('banner-click', {
        event: $event,
        path: '/quick-search/service'
      })}}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-py-2 tw-px-3 sm:tw-px-10"},[_c('h3',{staticClass:"tw-max-w-[19rem] md:tw-max-w-96 lg:tw-text-4xl md:tw-text-4xl sm:tw-text-3xl tw-text-[23px] tw-font-bold tw-mb-2 [&>span]:tw-underline [&>span]:tw-decoration-primary-300 [&>span]:tw-decoration-[3px] [&>span]:tw-underline-offset-[6px]",domProps:{"innerHTML":_vm._s(_vm.$t('paying_dearly'))}}),_vm._v(" "),_c('CButton',{staticClass:"tw-border-white tw-text-white tw-mt-4 hover:tw-bg-white hover:tw-text-[#004E95]",attrs:{"variant":"outline-light","size":_vm.isMobile ? 'sm' : 'md'}},[_vm._v("\n          "+_vm._s(_vm.$t('learn more'))+"\n        ")])],1),_vm._v(" "),_c('div',[_c('CPicture',{attrs:{"sources":{
            lg: _vm.$s(
              '/nuxt-static/img/landing-banners/vehicle-service-mobile.svg'
            )
          }}},[_c('CImg',{staticClass:" tw-absolute tw-right-0 tw-bottom-0 tw-w-[48%] md:tw-w-[45%] lg:tw-w-[47%]",attrs:{"draggable":"false","alt":_vm.$t('car_service'),"src":_vm.$s(
                '/nuxt-static/img/landing-banners/vehicle-service-desktop.svg'
              )}})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }