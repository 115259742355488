




































































import { defineComponent } from '@nuxtjs/composition-api'
import CImg from '~/components/shared/configurable/image/CImg.vue'

export default defineComponent({
  components: {
    CImg
  }
})
