



































import { defineComponent } from '@nuxtjs/composition-api'
import { mapActions, mapGetters } from 'vuex'
import NotificationCenter from '~/components/shared/configurable/notification-center/NotificationCenter.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import { APP_NS } from '~/store/modules/shared/app/state'
import { Position } from '~/models/app/position'
import { Trigger } from '~/models/app/trigger'

export default defineComponent({
  components: {
    NotificationCenter
  },
  props: {
    disableDropdown: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    placement: { type: String, default: Position.AUTO }
  },
  computed: {
    ...mapGetters(APP_NS, {
      isCar: 'isCar',
      isPlot: 'isPlot'
    }),
    ...mapGetters(USER_NS, {
      isGuest: 'isGuest'
    }),
    trigger: () => Trigger
  },
  methods: {
    ...mapActions(USER_NS, ['fetchNotificationCenterData']),
    handleShow() {
      this.$refs.notificationCenter.clearData()
      this.fetchNotificationCenterData({ page: 1 })
    }
  }
})
