var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full tw-sticky tw-bottom-0 sidebar-container sm:tw-space-y-6 md:tw-block tw-hidden"},[_c('FeedUser',{on:{"user-open":function($event){_vm.userOpen = $event}}}),_vm._v(" "),(_vm.showAnalyticsGlimpse)?_c('AnalyticsGlimpse',{staticClass:"sb-section sm:tw-block",class:{ 'tw-hidden': !_vm.userOpen },attrs:{"glimpse":_vm.statistics}}):_vm._e(),_vm._v(" "),(_vm.isPayingUser)?_c('div',{staticClass:"payments-summary-widget-container sb-section  sm:tw-block tw-mt-5"},[_c('PaymentsSummaryWidget',{attrs:{"in-sidebar":""}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cat-btns sb-section"},[_vm._l((_vm.catBtns),function(cat){return [(!cat.hide)?_c('FeedSideButton',{key:cat.catId,attrs:{"title":cat.label,"href":cat.link},on:{"click":function($event){return _vm.categoryLinkClicked($event, cat)}}},[_c('CCategoryIcon',{attrs:{"type":cat.type,"category-ids":cat.catId ? [cat.catId] : [],"in-sidebar":""}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(cat.label))]),_vm._v(" "),(cat.new)?_c('CNewBadge',{staticClass:"!tw-left-auto !tw-right-3 !tw-top-auto"}):_vm._e()],1):_vm._e()]})],2),_vm._v(" "),(_vm.isPc)?_c('div',{staticClass:"bottom-wrapper !tw-mb-5 tw-overflow-hidden"},[(_vm.showAdmanAds)?_c('CAdmanAd',{staticClass:"tw-mt-2",attrs:{"ad":{
        ws: '486',
        size: '300x250',
        passbackId: 'gpt-passback-137414'
      },"sticky":""}}):_c('CPosting',{attrs:{"unit":"in_house_hp_dt_sb","ad":{
        path: '/297119706/gen_hp_dt_sb',
        id: 'div-gpt-ad-1634727371839-0',
        sizes: [300, 250]
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }